<template>
  <span >
    <div v-show="isLoading" v-loading="isLoading" :element-loading-text="loadingText" class="fly-download" />
     <el-button :type="type" :size="size" class="ele-btn-icon ml-20" style="margin-left: 0" icon="el-icon-download" :disabled="disabled"
                @click="done">{{ label }}</el-button>
  </span>
</template>

<script>


import axios from "axios";
import  Config  from "@/config/setting.js"

export default {
  name: 'Hamburger',
  props: {
    label: {
      type: String,
      default: '导出'
    },
    url: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: null
    },

    size: {
      type: String,
      default: 'medium'
    },
    type: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      loadingText: '加载中',
      fileName: '',
      searchData: {
        page: 0
      },
      totalPage: 0
    }
  },
  watch: {
    params: {
      immediate: true,
      handler(val) {
        this.searchData = Object.assign(this.searchData, val)
      }
    }
  },
  created() {
    this.searchData = Object.assign(this.searchData, this.$props.params)
  },
  methods: {
    postFn() {
      if (this.$props.url) {
          let formdata = new window.FormData();
          // //console.log(this.searchData);
          // //console.log(formdata);
          formdata.append("file_name",  this.fileName);
          formdata.append("search", JSON.stringify(this.searchData));

          axios({
          url: this.$props.url,
          method: 'POST',
          timeout: 1800000,
          data: formdata,
          headers: {
              "Content-Type": "multipart/form-data",
          },
        }).then(res => {
            // //console.log(res);
          if (res.data.code === 0) {
            this.fileName = res.data.data.file_name
            this.totalPage = this.totalPage ? this.totalPage : parseInt(res.data.data.total_page)
            res.data.data.search.page = parseInt(res.data.data.search.page) + 1
            this.searchData = Object.assign(this.searchData, res.data.data.search)
            if (res.data.data.search.page <= this.totalPage) {
              this.loadingText = '正在打包，' + (res.data.data.search.page - 1) + '/' + this.totalPage
              this.postFn()
            } else {
              // debugger
              // //console.log('------------searchData', this.searchData)
              this.loadingText = '正在下载，请耐心等待'
              setTimeout(() => {
                const url =  Config.baseURL + '/backend/downLoadExportFile?filename=' + this.fileName
                this.isLoading = false
                this.loadingText = '加载中'
                this.fileName = ''
                this.searchData = Object.assign(this.searchData, {
                  page: 0
                })
                this.totalPage = 0
                window.location.href = url
              }, 1000)
            }
          } else {
            this.isLoading = false
            this.$message.error(res.msg)
          }
        }).catch(e => {
             this.isLoading = false
              this.$message.error(e.message)
        })
      } else {
        this.isLoading = false
        this.$message.error('参数有误')
      }
    },
    done() {
      this.$emit('clickBefore')
      this.isLoading = true
      this.postFn()
    }
  }
}
</script>

<style scoped>
  .fly-download {
    position: fixed;
    z-index: 2000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

  }

</style>
