<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" label-width="77px" class="ele-form-search d-flex f-end"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
          <el-form-item label="省:" label-width="35px">
            <el-select v-model="table.where.pid"  @change="handleChangeProv(table.where.pid)" placeholder='请选择省' class="input150" clearable>
              <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市:" label-width="35px">
            <el-select v-model="table.where.cid"  @change="handleChangeCity(table.where.cid)" placeholder='请选择市' class="input150" clearable>
              <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县/区:" label-width="65px">
            <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" class="input150" clearable>
              <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收支类型:" label-width="80px">
            <el-select v-model="table.where.type" placeholder="请选择" class="input150" clearable>
              <el-option  label="收入" value="1"/>
              <el-option label="支出" value="2"/>
            </el-select>
          </el-form-item>
            <div class="ml-20">
              <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">搜索
              </el-button>
              <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                <download class="ele-btn-icon"  label="导出" url="adminiaelist/adminiaelistExportExcel" :params="exportOrderParams()" v-if="permission.includes('sys:adminiaelist:export')"/>
						</div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="group_name" label="管理员角色"  show-overflow-tooltip min-width="100"/>
          <el-table-column prop="admin_real_name" label="管理员名称" show-overflow-tooltip min-width="100"/>
          <el-table-column prop="type_name" label="收支类型"  show-overflow-tooltip min-width="80"/>
          <el-table-column prop="admin_address" label="运营地区"  show-overflow-tooltip min-width="80"/>
          <el-table-column prop="admin_phone" label="联系方式"  show-overflow-tooltip min-width="80"/>
          <el-table-column prop="iae_price" label="收支金额（元）" s show-overflow-tooltip min-width="120"/>
          <el-table-column prop="iae_time" label="收支时间"  show-overflow-tooltip min-width="120"/>
          <el-table-column prop="remark" label="收支内容"  show-overflow-tooltip min-width="250"/>
          <el-table-column prop="acount_balance" label="收支余额动态（元）" show-overflow-tooltip min-width="160"/>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'修改员收支明细记录':'修改员收支明细记录'" :visible.sync="showEdit" width="450px"
               @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
                          
        <el-form-item label="区县id：" prop="aid">
            <el-input-number v-model="editForm.aid" controls-position="right" :min="0"
                              placeholder="请输入区县id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="城市id：" prop="cid">
            <el-input-number v-model="editForm.cid" controls-position="right" :min="0"
                              placeholder="请输入城市id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="省份id：" prop="pid">
            <el-input-number v-model="editForm.pid" controls-position="right" :min="0"
                              placeholder="请输入省份id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="后台运营商id：" prop="admin_id">
            <el-input-number v-model="editForm.admin_id" controls-position="right" :min="0"
                              placeholder="请输入后台运营商id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收入类型:1=收入,2=支出：" prop="type">
            <el-input-number v-model="editForm.type" controls-position="right" :min="0"
                              placeholder="请输入收入类型:1=收入,2=支出" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收支金额：" prop="iae_price">
            <el-input-number v-model="editForm.iae_price" controls-position="right" :min="0"
                              placeholder="请输入收支金额" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收支时间：" prop="iae_time">
            <el-input-number v-model="editForm.iae_time" controls-position="right" :min="0"
                              placeholder="请输入收支时间" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="状态:1=已使用,2=已到账：" prop="status">
            <el-input-number v-model="editForm.status" controls-position="right" :min="0"
                              placeholder="请输入状态:1=已使用,2=已到账" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="备注：" prop="remark">
            <el-input v-model="editForm.remark" placeholder="请输入备注" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="管理员角色:1=总管理员,2=省代理,3=市代理,4=代理商,5=县运营商：" prop="admin_group">
            <el-input-number v-model="editForm.admin_group" controls-position="right" :min="0"
                              placeholder="请输入管理员角色:1=总管理员,2=省代理,3=市代理,4=代理商,5=县运营商" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="管理员联系方式：" prop="admin_phone">
            <el-input v-model="editForm.admin_phone" placeholder="请输入管理员联系方式" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="管理员所辖区域：" prop="admin_address">
            <el-input v-model="editForm.admin_address" placeholder="请输入管理员所辖区域" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="账户当前余额：" prop="acount_balance">
            <el-input-number v-model="editForm.acount_balance" controls-position="right" :min="0"
                              placeholder="请输入账户当前余额" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="管理员真实姓名：" prop="admin_real_name">
            <el-input v-model="editForm.admin_real_name" placeholder="请输入管理员真实姓名" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="区县id：" prop="aid">
            <el-input-number v-model="editForm.aid" controls-position="right" :min="0"
                              placeholder="请输入区县id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="城市id：" prop="cid">
            <el-input-number v-model="editForm.cid" controls-position="right" :min="0"
                              placeholder="请输入城市id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="省份id：" prop="pid">
            <el-input-number v-model="editForm.pid" controls-position="right" :min="0"
                              placeholder="请输入省份id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="后台运营商id：" prop="admin_id">
            <el-input-number v-model="editForm.admin_id" controls-position="right" :min="0"
                              placeholder="请输入后台运营商id" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收入类型:1=收入,2=支出：" prop="type">
            <el-input-number v-model="editForm.type" controls-position="right" :min="0"
                              placeholder="请输入收入类型:1=收入,2=支出" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收支金额：" prop="iae_price">
            <el-input-number v-model="editForm.iae_price" controls-position="right" :min="0"
                              placeholder="请输入收支金额" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="收支时间：" prop="iae_time">
            <el-input-number v-model="editForm.iae_time" controls-position="right" :min="0"
                              placeholder="请输入收支时间" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="状态:1=已使用,2=已到账：" prop="status">
            <el-input-number v-model="editForm.status" controls-position="right" :min="0"
                              placeholder="请输入状态:1=已使用,2=已到账" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="备注：" prop="remark">
            <el-input v-model="editForm.remark" placeholder="请输入备注" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="管理员角色:1=总管理员,2=省代理,3=市代理,4=代理商,5=县运营商：" prop="admin_group">
            <el-input-number v-model="editForm.admin_group" controls-position="right" :min="0"
                              placeholder="请输入管理员角色:1=总管理员,2=省代理,3=市代理,4=代理商,5=县运营商" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="管理员联系方式：" prop="admin_phone">
            <el-input v-model="editForm.admin_phone" placeholder="请输入管理员联系方式" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="管理员所辖区域：" prop="admin_address">
            <el-input v-model="editForm.admin_address" placeholder="请输入管理员所辖区域" clearable/>
        </el-form-item>
                                                  
        <el-form-item label="账户当前余额：" prop="acount_balance">
            <el-input-number v-model="editForm.acount_balance" controls-position="right" :min="0"
                              placeholder="请输入账户当前余额" class="ele-fluid ele-text-left"/>
        </el-form-item>
                                                      
        <el-form-item label="管理员真实姓名：" prop="admin_real_name">
            <el-input v-model="editForm.admin_real_name" placeholder="请输入管理员真实姓名" clearable/>
        </el-form-item>
                          
      </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import download from '@/components/Download/index'
export default {
  name: "SysAdminIaelist",
    components: {download},
    data() {
    return {
      table: {url: '/adminiaelist/index', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
              
        aid: [
          {required: true, message: '请输入区县id', trigger: 'blur'}
        ],
                          
        cid: [
          {required: true, message: '请输入城市id', trigger: 'blur'}
        ],
                          
        pid: [
          {required: true, message: '请输入省份id', trigger: 'blur'}
        ],
                          
        admin_id: [
          {required: true, message: '请输入后台运营商id', trigger: 'blur'}
        ],
                          
        type: [
          {required: true, message: '请输入收入类型:1=收入,2=支出', trigger: 'blur'}
        ],
                          
        iae_price: [
          {required: true, message: '请输入收支金额', trigger: 'blur'},
					{
						pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
						message: '请输入大于0的有效数字', 
						trigger: 'blur'
					}
        ],
                          
        iae_time: [
          {required: true, message: '请输入收支时间', trigger: 'blur'}
        ],
                          
        status: [
          {required: true, message: '请输入状态:1=已使用,2=已到账', trigger: 'blur'}
        ],
                                    
        remark: [
          {required: true, message: '请输入备注', trigger: 'blur'}
        ],
                          
        admin_group: [
          {required: true, message: '请输入管理员角色:1=总管理员,2=省代理,3=市代理,4=代理商,5=县运营商', trigger: 'blur'}
        ],
                                    
        admin_phone: [
          {required: true, message: '请输入管理员联系方式', trigger: 'blur'}
        ],
                                    
        admin_address: [
          {required: true, message: '请输入管理员所辖区域', trigger: 'blur'}
        ],
                          
        acount_balance: [
          {required: true, message: '请输入账户当前余额', trigger: 'blur'}
        ],
                                    
        admin_real_name: [
          {required: true, message: '请输入管理员真实姓名', trigger: 'blur'}
        ],

      },
      provArr:[],
      cityArr:[],
      districtArr:[],
    }
  },
computed: {
    ...mapGetters(["permission"]),
},
  mounted() {
  },
  created(){
    this.$http.get('/common/province_list').then(res=>{
      let data=JSON.parse(res.data)
      this.provArr=data
    })

  },

  methods: {
      exportOrderParams() {
          const query = this.table.where
          // const params = this.params
          const selectionIds = this.choose.map(item => {
              return item.id
          })

          return {ids: selectionIds, ...query}
      },
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      let pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        let data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.table1.where.cid=''
        this.table1.where.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      let cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        let data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.table1.where.aid=''
      })
			}else{
				this.table1.where.pid = ''
				this.table1.where.aid = ''
			}
    },
    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/adminiaelist/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          this.showEdit = true;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/adminiaelist/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      //console.log(row)
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的员收支明细记录吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/adminiaelist/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({lock: true});
        this.$http.post('/adminiaelist/delete', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },

    /* 导出数据Excel */
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      // let name = this.table.where.name;
      // let phone = this.table.where.phone;
      // let cid = this.table.where.cid;
      // let pid = this.table.where.pid;
      // let area_id = this.table.where.area_id;
      // let page = this.table.where.area_id;
      //console.log('user data export')
      this.$http
          // .get("/user/exportExcel", {'name':name,'phone':phone,'pid':pid,'cid':cid,'area_id':area_id})
          .get("/adminiaelist/ExportList", info)
          .then((res) => {
            let data = res.data;
            if (data.code == 0) {
              // 下载文件
              window.location.href = data.data;
              this.$message({
                type: "success",
                message: "导出成功",
              });
            } else {
              this.$message.error("导出失败");
            }
          })
    },
                                                                                                                    }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
</style>